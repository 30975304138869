@import url('https://fonts.googleapis.com/css2?family=Arvo:wght@700&family=Nunito+Sans:opsz@6..12&display=swap');

.page-container {
	width: 100vw;
	height: 100vh;
	overflow-x: hidden;
	overflow-y: auto;
}

.hero {
	box-shadow: 0px 0px 8px #444444;
	background-image: linear-gradient(to right top, #051937, #004d7a, #008793, #00bf72, #a8eb12);
	/* background-image: linear-gradient(220deg,
			hsl(233deg 84% 53%) 0%,
			hsl(224deg 100% 43%) 21%,
			hsl(222deg 100% 40%) 30%,
			hsl(221deg 100% 37%) 39%,
			hsl(220deg 100% 34%) 46%,
			hsl(218deg 100% 30%) 54%,
			hsl(217deg 100% 27%) 61%,
			hsl(215deg 100% 24%) 69%,
			hsl(213deg 100% 21%) 79%,
			hsl(216deg 76% 20%) 100%); */
}

.corner-radius-16 {
	border-radius: 16px !important;
	border-color: transparent !important;
	border-width: 0 !important;
	box-shadow: 0px 0px 4px #bcbcbc !important;
}

.hero-title {
	font-family: 'Arvo', serif;
	font-weight: 700;
	color: white;
}

.section-title {
	font-family: 'Arvo', serif;
	font-weight: 700;
}

.text-color-dark {
	color: #333333;
}


.text-color-sub-dark {
	color: #676767;
}

.hero-text {
	font-family: 'Nunito Sans', sans-serif;
	font-weight: 400;
	color: white;
}

.section-subtitle {
	font-family: 'Nunito Sans', sans-serif;
	font-weight: 400;
}

.avatar {
	object-fit: cover;
}

.card-with-logo {
	height: 120px !important;
}

.image-fit-container {
	width: 100%;
	height: 100%;
	object-fit: contain;
}